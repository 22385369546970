const addUrl = (url, ul) => {
	if (!isValidUrl(url)) {
		alert("Keine gültige URL!");
		return false;
	}
	let urls = window.localStorage.getItem("xml-sitemap-urls");
	if (!urls) {
		urls = [];
	} else {
		urls = JSON.parse(urls);
	}
	urls.push(url);
	window.localStorage.setItem("xml-sitemap-urls", JSON.stringify(urls));
	return true;
}

const getURLs = url => {
	return new Promise((resolve, reject) => {
		if (!isValidUrl(url)) {
			alert("Keine gültige URL!");
			reject();
			return false;
		}
		var urls = window.localStorage.getItem("xml-sitemap-urls");
		if (!urls) {
			urls = [];
		} else {
			urls = JSON.parse(urls);
		}

		const socket = new WebSocket("wss://api.xml-sitemaps-generator.de/v1/sitemap/generate");
		socket.addEventListener("error", () => {
			reject();
		});
		socket.addEventListener("open", () => {
			socket.send(JSON.stringify({ url: url }));
		});
		socket.addEventListener("message", (event) => {
			let data = JSON.parse(event.data);
			if (data.status === 100) {
				if (isValidUrl(data.url)) {
					urls.push(data.url);
				}
			} else if (data.status === 200) {
				alert("URLs erfolgreich abgerufen! " + urls.length + " URLs hinzugefügt.");
				window.localStorage.setItem("xml-sitemap-urls", JSON.stringify(urls));
				socket.close();
				resolve();
			} else if (data.status === 429) {
				alert("Du hast die maximale Anzahl an URLs erreicht!");
				window.localStorage.setItem("xml-sitemap-urls", JSON.stringify(urls));
				socket.close();
				resolve();
			}
		});
	});
}

const updateList = (ul) => {
	let urls = window.localStorage.getItem("xml-sitemap-urls");
	if (urls && JSON.parse(urls) instanceof Array) {
		urls = JSON.parse(urls);
		ul.innerHTML = "";
		urls.forEach(url => {
			if (!isValidUrl(url)) {
				return;
			}
			let li = document.createElement("li");
			li.innerHTML = url;
			ul.appendChild(li);
		});
	}
}

const updateSitemap = (output) => {
	let urls = window.localStorage.getItem("xml-sitemap-urls");
	var sitemap = "<?xml version=“1.0″ encoding=“UTF-8″?>\n<urlset xmlns=“http://www.sitemaps.org/schemas/sitemap/0.9″>";
	if (urls && JSON.parse(urls) instanceof Array) {
		urls = JSON.parse(urls);
		urls.forEach(url => {
			if (!isValidUrl(url)) {
				return;
			}
			sitemap += "<url><loc>" + url + "</loc></url>";
		});
	}
	sitemap += "</urlset>";
	output.value = formatXML(sitemap);
};

const formatXML = (xml) => {
	var formatted = '';
	var reg = /(>)(<)(\/*)/g;
	xml = xml.replace(reg, '$1\r\n$2$3');
	var pad = 0;
	xml.split('\r\n').forEach(function (node, index) {
		var indent = 0;
		if (node.match(/.+<\/\w[^>]*>$/)) {
			indent = 0;
		} else if (node.match(/^<\/\w/)) {
			if (pad != 0) {
				pad -= 1;
			}
		} else if (node.match(/^<\w[^>]*[^\/]>.*$/)) {
			indent = 1;
		} else {
			indent = 0;
		}

		var padding = '';
		for (var i = 0; i < pad; i++) {
			padding += '  ';
		}

		formatted += padding + node + '\r\n';
		pad += indent;
	});

	return formatted;
}

const isValidUrl = (string) => {
	try {
		new URL(string);
		return true;
	} catch (err) {
		return false;
	}
}

if (document.querySelector(".ce_rsce_generator")) {
	let urls = window.localStorage.getItem("xml-sitemap-urls");
	if(!urls || !(urls instanceof Array)) {
		urls = [];
		window.localStorage.setItem("xml-sitemap-urls", JSON.stringify(urls));
	}
	document.querySelectorAll(".ce_rsce_generator").forEach((element) => {
		let addUrlField = element.querySelector(".add-url-field");
		let addUrlButton = element.querySelector(".add-url-button");
		let getUrlsField = element.querySelector(".get-urls-field");
		let getUrlsButton = element.querySelector(".get-urls-button");
		let removeAllUrlsButton = element.querySelector(".remove-all-urls-button");
		let urlList = element.querySelector("ul.url-list");
		let output = element.querySelector(".generator-output");
		updateSitemap(output);
		updateList(urlList);

		removeAllUrlsButton.addEventListener("click", () => {
			window.localStorage.setItem("xml-sitemap-urls", JSON.stringify([]));
			urlList.innerHTML = "";
			updateSitemap(output);
		});

		addUrlField.addEventListener("keypress", (e) => {
			if (e.key === "Enter") {
				if (addUrl(addUrlField.value, urlList)) {
					updateSitemap(output);
					updateList(urlList);
					addUrlField.value = "";
				}
			}
		});

		addUrlButton.addEventListener("click", () => {
			if (addUrl(addUrlField.value, urlList)) {
				updateSitemap(output);
				updateList(urlList);
				addUrlField.value = "";
			}
		});

		getUrlsField.addEventListener("keypress", (e) => {
			if (e.key === "Enter") {
				getUrlsButton.click();
			}
		});

		getUrlsButton.addEventListener("click", () => {
			getUrlsButton.disabled = true;
			getUrlsButton.classList.add("loading");
			getURLs(getUrlsField.value).then(() => {
				updateSitemap(output);
				updateList(urlList);
				getUrlsField.value = "";
				getUrlsButton.disabled = false;
				getUrlsButton.classList.remove("loading");
			}).catch(() => {
				alert("Fehler beim Abrufen der URLs!");
				getUrlsButton.disabled = false;
				getUrlsButton.classList.remove("loading");
			});
		});

		output.addEventListener("click", () => {
			output.select();
			document.execCommand("copy");
			output.closest(".generator-output-container").classList.add("copied");
			setTimeout(() => {
				output.closest(".generator-output-container").classList.remove("copied");
			}, 2000);
		});
	});
}